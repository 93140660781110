import axios from 'axios';

export class CustomerService {
  async fetchCustomerStats(id) {
    return (await axios.get('/user-stats/' + id)).data;
  }

  async fetchCustomerMetrics(id) {
    return (await axios.get('/surveys/exam/' + id)).data;
  }
}
