var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"allow-add":false,"title":"In App Products"},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"primary-action",fn:function(){return [_c('v-btn',{attrs:{"disabled":!_vm.valid || !_vm.getUser().scopes.includes('iaps:edit'),"color":"primary","elevation":"0"},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-disk")]),_vm._v(" Save ")],1)]},proxy:true},{key:"sku",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sku)+" ")]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[function (v) { return (!!v && +v > 0) || 'Price must be provided'; }],"dense":"","outlined":"","suffix":"USD","type":"number"},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]}},{key:"discount",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[
          function (v) { return _vm.checkLess(v, item.price) || 'Discount must be less than Price'; }
        ],"dense":"","outlined":"","suffix":"USD","type":"number"},model:{value:(item.discountPrice),callback:function ($$v) {_vm.$set(item, "discountPrice", $$v)},expression:"item.discountPrice"}})]}},{key:"discountMode",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":""},model:{value:(item.discountMode),callback:function ($$v) {_vm.$set(item, "discountMode", $$v)},expression:"item.discountMode"}})]}},{key:"action",fn:function(ref){
        var item = ref.item;
return [(!(item.sku === 'lifetime') && !(item.sku === 'dat_lifetime_v2') && _vm.getUser().scopes.includes('iaps-offers:view'))?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil ")]):_vm._e()]}}])}),_c('loading-dialog',{attrs:{"message":"Updating Products"},model:{value:(_vm.editLoading),callback:function ($$v) {_vm.editLoading=$$v},expression:"editLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }