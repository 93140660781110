<template>
  <div>
    <v-row>
      <v-col cols="12" height="100%" md="4" sm="6">
        <v-card height="100%">
          <div class="d-flex align-center pa-4" style="background-color: #DA57A7; color: white">
            <v-icon class="mr-2" color="white" size="28">mdi-account</v-icon>
            <h5 class="text-h5">Personal Info</h5>
          </div>

          <v-card-text class="px-6">
            <div v-if="customer_details">
              <div v-if="customer_details.name">
                <p class="text-subtitle-1 mb-0"><b>Name</b> : {{ customer_details.name }}</p>
              </div>

              <div v-if="customer_details.email">
                <p class="text-subtitle-1 mb-0"><b>Email</b> : {{ customer_details.email }}</p>
              </div>
            </div>
            <span v-else class="px-6">Please wait... </span>

            <div v-if="customer_details && customer_details.on_boarding">
              <div v-if="customer_details.on_boarding.real_name">
                <p class="text-subtitle-1 mb-0"><b>Real Name</b> : {{ customer_details.on_boarding.real_name }}</p>
              </div>

              <div v-if="customer_details.on_boarding.user_name">
                <p class="text-subtitle-1 mb-0"><b>Username</b> : {{ customer_details.on_boarding.user_name }}</p>
              </div>

              <div v-if="customer_details.on_boarding.gender">
                <p class="text-subtitle-1 mb-0"><b>Gender</b> : {{ customer_details.on_boarding.gender }}</p>
              </div>

              <div v-if="customer_details.on_boarding.age">
                <p class="text-subtitle-1 mb-0"><b>Age</b> : {{ customer_details.on_boarding.age }} Years</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="4" sm="6">
        <v-card height="100%">
          <div class="d-flex align-center pa-4" style="background-color: #70AAD5; color: white">
            <v-icon class="mr-2" color="white" size="28">mdi-clipboard-search-outline</v-icon>
            <h5 class="text-h5">Previous DAT</h5>
          </div>

          <v-card-text v-if="customer_details && customer_details.on_boarding" class="px-6">
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('is_mcat_taken_before') }}</p>
              <p v-if="customer_details.on_boarding.is_mcat_taken_before !== null" class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.is_mcat_taken_before ? 'Yes' : 'No' }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('mcat_previous_test_date') }}</p>
              <p v-if="customer_details.on_boarding.mcat_previous_test_date  !== null && customer_details.on_boarding.mcat_previous_test_date  !== ''"
                 class="text-subtitle-1 mb-0">
                {{ formatDate(customer_details.on_boarding.mcat_previous_test_date) }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('mcat_previous_test_score') }}</p>
              <p v-if="customer_details.on_boarding.mcat_previous_test_score" class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.mcat_previous_test_score }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('last_practice_score') }}</p>
              <p v-if="customer_details.on_boarding.last_practice_score" class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.last_practice_score }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4">No data found for previous DAT!</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="4">
        <v-card height="100%">
          <div class="d-flex align-center pa-4" style="background-color: #EE8782; color: white">
            <v-icon class="mr-2" color="white" size="28">mdi-clipboard-text-clock</v-icon>
            <h5 class="text-h5">Upcoming Test</h5>
          </div>

          <v-card-text v-if="customer_details && customer_details.on_boarding" class="px-6">
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('mcat_goal_score') }}</p>
              <p class="text-subtitle-1 mb-0">{{ customer_details.on_boarding.mcat_goal_score }}</p>
            </div>

            <div v-if="customer_details.on_boarding.mcat_test_date">
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('mcat_test_date') }}</p>
              <p class="text-subtitle-1 mb-0">{{ formatDate(customer_details.on_boarding.mcat_test_date) }}</p>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4">No data found for Upcoming Test!</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="6">
        <v-card height="100%">
          <div class="d-flex align-center pa-4" style="background-color: #495DB7; color: white">
            <v-icon class="mr-2" color="white" size="28">mdi-clipboard-pulse-outline</v-icon>
            <h5 class="text-h5">Study Analysis</h5>
          </div>

          <v-card-text v-if="customer_details && customer_details.on_boarding" class="px-6">
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('is_remember_study') }}</p>
              <p class="text-subtitle-1 mb-0">{{ customer_details.on_boarding.is_remember_study ? 'Yes' : 'No' }}</p>
            </div>

            <div v-if="customer_details.on_boarding.subject_difficulty_ranking">
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('subject_difficulty_ranking') }}</p>
              <p v-for="(subject,s) in sortByRanking(customer_details.on_boarding.subject_difficulty_ranking)"
                 :key="s" class="text-subtitle-1 mb-0"
              >
                {{ capitalize(subject[0].replace('_', ' ')) }}
              </p>
            </div>

            <div v-if="customer_details.on_boarding.mcat_daily_study">
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('mcat_daily_study') }}</p>
              <p class="text-subtitle-1 mb-0">{{ customer_details.on_boarding.mcat_daily_study }}</p>
            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4">No Study Analysis done yet!</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" height="100%" md="6">
        <v-card height="100%">
          <div class="d-flex align-center pa-4" style="background-color: #F5C187; color: white">
            <v-icon color="white" size="28">mdi-head-question</v-icon>
            <h5 class="text-h5">Study Habits</h5>
          </div>

          <v-card-text v-if="customer_details && customer_details.on_boarding" class="px-6">
            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('is_understand_study_habits') }}</p>
              <p v-if="customer_details.on_boarding.is_understand_study_habits !== null" class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.is_understand_study_habits ? 'Yes' : 'No' }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('study_habits_importance') }}</p>
              <p v-if="customer_details.on_boarding.study_habits_importance" class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.study_habits_importance }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('study_timing') }}</p>
              <p v-if="customer_details.on_boarding.study_timing" class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.study_timing }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('commitment') }}</p>
              <p v-if="customer_details.on_boarding.commitment" class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.commitment ? 'Yes' : 'No' }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('study_material') }}</p>
              <p v-if="customer_details.on_boarding.study_material &&
                       customer_details.on_boarding.study_material.length > 0"
                 class="text-subtitle-1 mb-0"
              >
                {{ customer_details.on_boarding.study_material.join(',') }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

            <div>
              <p class="text-subtitle-1 font-weight-bold mb-0">{{ getQuestion('mcat_improvements') }}</p>
              <p v-if="customer_details.on_boarding.mcat_improvements && customer_details.on_boarding.mcat_improvements.length > 0"
                 class="text-subtitle-1 mb-0">
                {{ customer_details.on_boarding.mcat_improvements.filter(s_m => s_m && s_m !== '').join(',') }}</p>
              <p v-else class="text-subtitle-1 mb-0">N/A</p>
            </div>

          </v-card-text>
          <v-card-text v-else class="pa-4">No data found for Study Habits!</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" height="100%">
        <v-card height="100%">
          <div class="d-flex align-center pa-4" style="background-color: #7FD7B6; color: white">
            <v-icon class="mr-2" color="white" size="28">mdi-clipboard-edit-outline</v-icon>
            <h5 class="text-h5">Test Survey</h5>
          </div>

          <v-card-text v-if="customer_metrics" class="px-6">
            <div v-for="(question, q) in customer_metrics.questions" :key="q">
              <div>
                <p class="text-subtitle-1 font-weight-bold mb-0">{{ question.question }}</p>
                <p class="text-subtitle-1 mb-0">{{ question.answer }}</p>
              </div>

            </div>
          </v-card-text>
          <v-card-text v-else class="pa-4"> No data found for Impact Metrics!</v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <LoadingDialog v-model="loading" message="Loading..."/>
  </div>
</template>

<script>
import {CustomerService} from "../../services/customer-service";
import moment from "moment";
import LoadingDialog from "../../components/LoadingDialog";

export default {
  name: "CustomersDetail",
  components: {LoadingDialog},
  data() {
    return {
      loading: false,
      customer_details: null,
      customer_metrics: null,
      customer_service: new CustomerService(),
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        let _id = this.$route.query.id
        this.customer_details = await this.customer_service.fetchCustomerStats(_id);
        this.customer_metrics = await this.customer_service.fetchCustomerMetrics(_id);
        if (this.customer_details && this.customer_details.on_boarding && this.customer_details.on_boarding.study_material.length > 0) {
          this.customer_details.on_boarding.study_material = this.customer_details.on_boarding.study_material.filter(m => m && m !== '')
        }
        this.loading = false
      } catch (e) {
        console.log(e)
      }
    },
    formatDate(date) {
      return moment(date).format('dddd, MMMM Do YYYY')
    },

    capitalize(word) {
      return word
          .toLowerCase()
          .replace(/\w/, firstLetter => firstLetter.toUpperCase());
    },

    sortByRanking(obj) {
      const sortable = Object.entries(obj);
      return sortable.sort((a, b) => a[1] - b[1])
    },

    getQuestion(item) {
      switch (item) {
        case 'real_name':
          return 'Real Name';

        case 'user_name':
          return 'Username';

        case 'mcat_goal_score':
          return 'What is your DAT score goal?';

        case 'mcat_test_date':
          return 'When you are going to take the DAT?';

        case 'is_mcat_taken_before':
          return 'Have you taken the DAT before?';

        case 'mcat_previous_test_date':
          return 'When was your last DAT?';

        case 'mcat_previous_test_score':
          return 'What was your DAT score?';

        case 'last_practice_score':
          return 'If you haven\'t taken the DAT, what was your last practice score?';

        case 'gender':
          return 'What is your gender?';

        case 'is_remember_study':
          return 'Do you want to remember more from what you study?';

        case 'subject_difficulty_ranking':
          return 'What was your most difficult subject in DAT? Rank them in the order of your most difficult to least difficult?';

        case 'mcat_daily_study':
          return 'How much will you study with KOTC for the DAT daily?';

        case 'age':
          return 'What is your age?';

        case 'is_understand_study_habits':
          return 'Do you want to understand your study habits?';

        case 'study_habits_importance':
          return 'How well do you want to understand your study habits?';

        case 'commitment':
          return 'Is contract accepted?';

        case 'study_timing':
          return 'When do you like to study most?';

        case 'study_material':
          return 'What study materials do you use?';

        case 'mcat_improvements':
          return 'Which of these would you like to improve? Select all and apply?';

        case 'last_step':
          return 'last_step';

        default:
          return null;
      }
    }
  }
}
</script>

<style scoped>

</style>