<template>
  <div>
    <div class="dashboard-cards">
      <stat-card title="Total Games" :value="games" color="secondary" :loading="gamesLoading" filter
                 @applyFilter="applyFilterGames"/>
      <stat-card title="Total Multiplayer Games" :value="dashboardCards.multiplayer_games" color="purple"
                 :loading="loading"/>
      <stat-card title="Multiplayer Games Won" :value="dashboardCards.win" color="green" :loading="loading"/>
      <stat-card title="Multiplayer Games Lost" :value="dashboardCards.lost" color="pink" :loading="loading"/>
    </div>
    <div class="dashboard-graphs">
      <v-card class="dashboard-graph">
        <PieChartComp :fetch="fetchQuestions" @completed="fetchGames = true"/>
      </v-card>
      <v-card class="dashboard-graph">
        <bar-chart-games :fetch="fetchGames" @completed="fetchUsers = true"/>
      </v-card>
    </div>
    <div class="dashboard-graphs-one">
      <v-card class="dashboard-graph">
        <bar-chart-users :fetch="fetchUsers" @completed="fetchModes = true"/>
      </v-card>
      <v-card class="dashboard-graph">
        <LineChartComp :fetch="fetchModes"/>
      </v-card>
    </div>
  </div>
</template>

<script>

import StatCard from "@/components/dashboard/StatCard";
import {DashboardService} from "@/services/dashboard-service";
import PieChartComp from "@/components/dashboard/charts/pie-chart";
import BarChartGames from "@/components/dashboard/charts/bar-chart-games";
import BarChartUsers from "@/components/dashboard/charts/bar-chart-users";
import LineChartComp from "@/components/dashboard/charts/line-chart";
import {oneSignalApiKey} from "@/utils/local";
import crypto from 'crypto'

export default {
  name: 'Home',
  components: {LineChartComp, BarChartUsers, BarChartGames, PieChartComp, StatCard},
  data: () => ({
    loading: false,
    gamesLoading: false,
    loadingDialog: false,
    dashboardCards: {},
    games: 0,
    service: new DashboardService(),
    fetchQuestions: false,
    fetchGames: false,
    fetchUsers: false,
    fetchModes: false,
  }),
  mounted() {
    try {
      const token = localStorage.getItem('auth_token');
      const user = token ? JSON.parse(localStorage.getItem('auth_user')) : null;
      this.$OneSignal.init({
        appId: "18ef26ff-ce97-4312-9f29-26db4c1f16d9",
        safari_web_id: "web.onesignal.auto.6514249a-4cb8-451b-a889-88f5913c9a7f",
        promptOptions: {
          siteName: 'DAT - King of the Curve',
          actionMessage: "We'd like to show you notifications for the latest updates.",
          exampleNotificationTitle: 'Flagged Question',
          exampleNotificationMessage: 'User johndoa123@gmail.com  has flagged a question.',
          exampleNotificationCaption: 'You can unsubscribe anytime',
          acceptButtonText: "ALLOW",
          cancelButtonText: "NO THANKS",
          autoAcceptTitle: 'Click Allow',
          slidedown: {
            prompts: [
              {
                type: "push",
                autoPrompt: true,
                text: {
                  actionMessage: "We'd like to show you notifications for the latest updates.",
                  acceptButton: "Allow",
                  cancelButton: "No Thanks"
                },
                delay: {
                  pageViews: 1,
                  timeDelay: 5
                }
              }
            ]
          }
        },
        welcomeNotification: {
          disable: false,
          title: 'Welcome to DAT KOTC admin',
          message: 'Thanks for subscribing. Now you can stay up to date.'
        },
        notifyButton: {
          enable: true,
          size: 'medium',
          showCredit: false,
        },
        subdomainName: "dat-kotc",
      }).then(() => {
        const hmac = crypto.createHmac('sha256', oneSignalApiKey).update(user.id.toString());
        this.$OneSignal.setExternalUserId(user.id.toString(), hmac.digest('hex')).then(() => {
          console.log('user id set')
        }).catch((e) => {
          console.log('id set error')
          console.log(e)
        })
      }).catch((e) => {
        console.log(e)
      });
    } catch (e) {
      console.log(e)
    }
    // this.getData()
    this.getDashboardData()
  },
  methods: {
    // async uploadData() {
    //
    //   for (let i = 0; i < this.videos.length; i++) {
    //     const blob = await this.generateThumbnail(this.videos[i]);
    //     let thumbRef = storage.ref('videos/thumbnails/' + blob.name);
    //     let thumbTask = thumbRef.put(blob.file);
    //     await thumbTask.then(() => {
    //       window.console.log('thumbnail posted', i)
    //     }).catch((e) => window.console.log('uploading image error => ', e))
    //   }
    //
    //   console.log('done')
    // },
    // async generateThumbnail(url) {
    //   const urlObject = decodeURIComponent(url)
    //   let fileName = urlObject.split('videos/').pop()
    //   fileName = fileName.split('?').shift()
    //
    //   const canvas = document.createElement('canvas')
    //   const context = canvas.getContext('2d')
    //   const video = document.createElement('video')
    //   video.setAttribute('src', url)
    //   video.setAttribute('crossorigin', 'anonymous')
    //   video.load()
    //   let thumbnail = await new Promise((resolve) => {
    //     video.onloadedmetadata = async () => {
    //       canvas.width = video.videoWidth
    //       canvas.height = video.videoHeight
    //       video.currentTime = video.duration / 2
    //       await video.play()
    //       context.drawImage(video, 0, 0)
    //       video.pause()
    //       const blob = await new Promise((resolve) => {
    //         return canvas.toBlob(function (blob) {
    //           resolve(blob)
    //         })
    //       })
    //       resolve(blob)
    //     }
    //   })
    //
    //   return {
    //     name: fileName,
    //     file: thumbnail
    //   }
    // },
    async getDashboardData() {
      this.loading = true
      await this.applyFilterGames()
      this.dashboardCards = await this.service.fetch(0)
      this.loading = false
      this.fetchQuestions = true
    },
    async applyFilterGames(data = null) {
      this.gamesLoading = true
      const games = await this.service.fetch(5, data)
      this.games = games.games
      this.gamesLoading = false
    }
  },

};
</script>

<style scoped>
.dashboard-cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-bottom: 30px;
}

.dashboard-graphs {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-bottom: 30px;
}

.dashboard-graphs-one {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-bottom: 30px;
}

@media screen and (min-width: 580px) {
  .dashboard-cards {
    grid-template-columns: 1fr 1fr;
  }

  .dashboard-graphs {
    grid-template-columns: 1fr 1fr;
  }

  .dashboard-graphs-one {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1000px) {
  .dashboard-cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .dashboard-graphs {
    grid-template-columns: 1fr 2fr;
  }

  /*.dashboard-graphs-one {*/
  /*  grid-template-columns: 2fr 1fr;*/
  /*}*/
}

</style>
