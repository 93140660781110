import { render, staticRenderFns } from "./NotificationForm.vue?vue&type=template&id=12913703&scoped=true&"
import script from "./NotificationForm.vue?vue&type=script&lang=js&"
export * from "./NotificationForm.vue?vue&type=script&lang=js&"
import style0 from "./NotificationForm.vue?vue&type=style&index=0&id=12913703&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12913703",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardTitle,VCheckbox,VCombobox,VDialog,VForm,VSelect,VTextField,VTextarea,VTimePicker})
