import firebase from "firebase/app";
import 'firebase/auth';
import "firebase/firestore";
import "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyCtMbQYmEvPkqagdud4dk-hXl_pR1C0MTw",
    authDomain: "dat---king-of-the-curve.firebaseapp.com",
    projectId: "dat---king-of-the-curve",
    storageBucket: "dat---king-of-the-curve.appspot.com",
    messagingSenderId: "562411001221",
    appId: "1:562411001221:web:ec24c9c6c89c8b4509a0ec",
    measurementId: "G-3PW2FSBXGV"
};

firebase.initializeApp(firebaseConfig);

//utils
const db = firebase.firestore();
const storage = firebase.storage();

const firestore = firebase.firestore

//collections references

const users = db.collection('v2_users')

export {
    db, storage, firestore, users
}

