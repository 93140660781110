<template>
  <div>
    <data-table
        :allow-add="false"
        :headers="headers"
        :loader="loadData"
        title="Customers"
        :default-footer="false"
        :show-search="false"
    >
      <template #primary-action>
        <div style="width: 400px; margin-right: 20px; max-width: 100%">
          <v-text-field
              v-model="search"
              solo
              label="Search by email or name..."
              dense
              hide-details
              append-icon="mdi-magnify"
              @click:append="searchData"
              @change="searchData"
          />
        </div>
        <v-btn v-if="getUser() ? getUser().scopes.includes('customers:export') : false" class="mr-3"
               color="primary" elevation="0" @click="exportCustomers(false)" outlined>Export
        </v-btn>
        <v-btn v-if="getUser() ? getUser().scopes.includes('customers:export') : false" class="mr-3"
               color="primary" elevation="0" @click="exportCustomers(true)">Export All
        </v-btn>
      </template>
      <template #is_premium="{ item }">
        <v-checkbox v-model="item.is_premium" dense hide-details readonly/>
      </template>
      <template #premium_trial="{ item }">
        <v-checkbox v-model="item.premium_trial" dense hide-details readonly/>
      </template>
      <template #actions="{ item }">
        <v-icon v-if="getUser() ? getUser().scopes.includes('customer:detail') : false" color="primary" small @click="detail(item)">mdi-eye</v-icon>
        <v-icon v-if="!item.is_premium" color="success" small @click="openPremiumEditor(item)">mdi-check-circle</v-icon>
        <v-icon v-if="item.is_premium && item.premium_trial" color="error" small @click="toNotPremium(item)">
          mdi-close-circle
        </v-icon>
      </template>
      <template v-slot:custom-footer>
        <div class="v-data-footer pa-3 d-flex justify-end align-center">
          <v-btn :disabled="!prevItems || prevItems.length <= 0" class="mr-2" @click="getData(false)" elevation="0"
                 color="primary">Prev
          </v-btn>
          <v-btn :disabled="!items || items.length < 10" @click="getData(true)" elevation="0" color="primary">Next
          </v-btn>
        </div>
      </template>
    </data-table>
    <v-dialog v-model="toPremium" width="400">
      <v-card style="padding: 40px">
        <v-form ref="makePremium">
          <p class="span-2 form__title mb-0">Premium Trial</p>
          <p class="span-2 mb-6">This will set the user's premium status to true till expiry date.</p>
          <v-text-field v-model="premium_trial_expiry" :rules="[required(),dateValidation]" dense label="Expiry Date"
                        outlined
                        type="date"/>
          <v-btn
              color="primary"
              elevation="0"
              style="width: 100%"
              @click="makePremium"
          >Submit
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" message="Loading..."/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import {getUser} from '@/utils/local';
import {users} from "@/plugins/firebase";
import {dateValidation, required} from "@/utils/validators";
import LoadingDialog from "@/components/LoadingDialog";
import moment from 'moment'

export default {
  components: {LoadingDialog, DataTable},

  data: () => ({
    items: [],
    prevItems: [],
    loading: false,

    toPremium: false,
    premium_trial_expiry: null,
    user: null,

    headers: [
      {
        text: 'Display Name',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        width: 120,
        text: 'Is Premium',
        value: 'is_premium',
        sortable: false
      },
      {
        width: 120,
        text: 'Premium Trial',
        value: 'premium_trial',
        sortable: false
      },
      {
        width: 120,
        text: 'Actions',
        value: 'actions',
        sortable: false
      },
    ],
    page: 1,
    next: false,
    prev: false,
    search: null
  }),

  methods: {
    getUser,
    required,
    dateValidation,
    async loadData() {
      try {
        if (!this.search || this.search === '') {
          if (this.page <= 1) {
            const snapshot = await users.orderBy('id').limit(10).get()
            const data = []
            snapshot.docs.map(doc => {
                  data.push({id: doc.id, ...doc.data()})
                }
            )
            this.items = data
            this.page++
            return data
          } else {
            let lastVisible
            let snapshot
            if (this.prev && this.prevItems && this.prevItems.length > 0) {
              lastVisible = {...this.prevItems.pop()};
              console.log("last", lastVisible);
              snapshot = await users.orderBy('id').startAt(lastVisible.id).limit(10).get()
            } else if (this.next) {
              lastVisible = {...this.items[this.items.length - 1]};
              this.prevItems.push(this.items[0])
              console.log("last", lastVisible);
              snapshot = await users.orderBy('id').startAfter(lastVisible.id).limit(10).get()
            }

            console.log(snapshot)
            const data = []
            snapshot.docs.map(doc => {
                  data.push({id: doc.id, ...doc.data()})
                }
            )
            this.items = data
            return data
          }
        } else {
          this.page = 1
          this.prevItems = []
          let snapshot
          if (this.search.includes('@')) {
            snapshot = await users.where('email', '==', this.search).limit(10).get()
          } else {
            snapshot = await users.where('name', '==', this.search).limit(10).get()
          }
          const data = []
          snapshot.docs.map(doc => {
                data.push({id: doc.id, ...doc.data()})
              }
          )
          this.items = data
          return data
        }
      } catch (e) {
        console.log(e, 'error')
      }
    },
    getData(flag) {
      if (flag) {
        this.prev = false
        this.next = true
      } else {
        this.prev = true
        this.next = false
      }
      document.getElementById('refresh').click()
    },
    searchData() {
      document.getElementById('refresh').click()
    },
    async exportCustomers(flag) {
      this.loading = true
      let csv
      if (flag) {
        const snapshot = await users.get()
        const data = []
        snapshot.docs.map(doc => {
              data.push({id: doc.id, ...doc.data()})
            }
        )
        csv = this.toCSV(data)
      } else {
        csv = this.toCSV(this.items)
      }
      const name = 'customers.csv'
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    },
    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'Name,Email\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        if (array[i].name) {
          array[i].name = array[i].name.replaceAll(',', ' ')
          line += array[i].name
        }
        line += ',' + array[i].email
        line += '\r\n'


        str += line

      }

      return str
    },
    openPremiumEditor(item) {
      this.user = item
      this.toPremium = true
    },
    async makePremium() {
      if (this.$refs.makePremium.validate()) {
        this.loading = true
        let data = {
          is_premium: true,
          premium_trial: true,
          premium_trial_expiry: moment(this.premium_trial_expiry).format('YYYY-MM-DDT00:00')
        }


        await users.doc(this.user.id).update(data).then(() => {
        }).catch((e) => {
          window.console.log(e)
        })
        this.toPremium = false
        this.premium_trial_expiry = null
        this.user = null
        this.$refs.makePremium.reset()
        document.getElementById('refresh').click()
        this.loading = false
      }
    },
    async toNotPremium(item) {
      if (confirm('Are you sure? This will end premium trial.')) {
        this.loading = true;
        let data = {
          is_premium: false,
          premium_trial: false,
          premium_trial_expiry: null
        }


        await users.doc(item.id).update(data).then(() => {
        }).catch((e) => {
          window.console.log(e)
        })
        document.getElementById('refresh').click()
        this.loading = false;
      }
    },
    async detail(item) {
      window.open('/customer-detail?id=' + item.id)
    },
    formatDate(date) {
      return moment(date).format('dddd, MMMM Do YYYY')
    },
  }
};
</script>

<style>
.v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: transparent !important;
  transform: none;
  transition: none;
}

.v-ripple__container {
  display: none !important;
}

.v-input--selection-controls__ripple, .v-input--selection-controls .v-input__slot, .v-input--selection-controls .v-radio {
  cursor: default;
}
</style>
