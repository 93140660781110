<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Question Media"
        :allow-add="getUser() ? getUser().scopes.includes('question-media:new') : false"
        @add-new="addNew"
        @done="$router.back()"
        :delete-handler="getUser() ? getUser().scopes.includes('question-media:delete') ?  service.delete : null : null"
        :edit-handler="getUser() ? getUser().scopes.includes('question-media:edit') ? edit : null : null"
    >
      <template v-slot:primary-action>
        <v-btn elevation="0" class="mr-3" @click="reRunMedia">Re-Run</v-btn>
      </template>
      <template #images="{ item }">
        <span v-if="item.images && item.images.length > 0">{{ fetchImage(item.images[0]) }}</span>
        <span v-else-if="item.videos && item.videos.length > 0">{{ fetchImage(item.videos[0]) }}</span>
        <span v-else-if="item.pdfs && item.pdfs.length > 0">{{ fetchImage(item.pdfs[0]) }}</span>
        <span v-else>No Media</span>
      </template>
      <template #keywords="{ item }">
        <v-chip class="ma-1" v-for="(keyword, i) of item.keywords.slice(0,5)" :key="i" small>{{ keyword }}</v-chip>
      </template>
      <template #questions="{ item }">
      <span v-if="item.questions">
              <v-chip class="ma-1" v-for="(question, i) of item.questions.slice(0,3)" :key="i"
                      small>{{ question.statement.substring(0, 75) }} {{ question.statement.length > 75 ? '...' : '' }}</v-chip>
      </span>
      </template>
    </data-table>
    <LoadingDialog v-model="loading" message="Loading..."/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import {getUser} from '@/utils/local';
import {QuestionMediaService} from "@/services/question_media-service";
import {storage} from "@/plugins/firebase";
import LoadingDialog from "@/components/LoadingDialog";

export default {
  components: {LoadingDialog, DataTable},

  mounted() {
  },

  data: () => ({
    loading: false,
    service: new QuestionMediaService(),

    headers: [
      {
        text: 'Image',
        value: 'images',
        width: 120
      },
      {
        text: 'Keywords',
        value: 'keywords',
        sortable: false,
        width: '30%'
      },
      {
        text: 'Questions',
        value: 'questions',
        sortable: false,
        width: '30%'
      }
    ]
  }),

  methods: {
    getUser,
    addNew() {
      this.$router.push('/question-media');
    },
    edit(item) {
      this.$router.push(`/question-media?id=${item.id}`);
    },
    loadData() {
      return this.service.fetchAll();
    },
    fetchImage(img) {
      const name = storage.refFromURL(img).name
      return name.substring(0, name.indexOf('~') !== -1 ? name.indexOf('~') : name.length);
    },
    async reRunMedia() {
      if (confirm('Warning! Are you sure?')) {
        this.loading = true;
        await this.service.reRunMedia()
        this.loading = false;
        document.getElementById('refresh').click()
      }
    }
  }
};
</script>

<style scoped></style>
